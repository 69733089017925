.mat-toggle p {
  display: inline-block;
  /*line-height: 1.5rem;*/
  /*margin: 0 1.25rem 0;*/
  /*vertical-align: middle;*/
  /*transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);*/
}

.mat-toggle input {
  display: none;
}

.mat-toggle .switch {
  margin-left: 15px;
  display: inline-block;
  position: relative;
  width: 2.5rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.26);
  -webkit-transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}

.mat-toggle .switch::before {
  content: '';
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: #fafafa;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-toggle .switch:active::before {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.28), 0 0 0 1.25rem rgba(128, 128, 128, 0.1);
}

.mat-toggle.checked .switch {
  background: rgba(23, 95, 157, 0.5);
}

.mat-toggle.checked .switch::before {
  left: 1.25rem;
  background: #175f9d;
}

.mat-toggle.checked .switch:active::before {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.28), 0 0 0 1.25rem rgba(139, 195, 74, 0.2);
}
