// Global styles for the app, overriding some default bootstrap styles
@import '../css/_variables';
// Grab bootstrap variables, we use them in some definitions
@import 'bootstrap/variables';
@import './_typography';

a {
  color: inherit;
  cursor: pointer;
  &:hover {
    color: inherit;
  }
}
.form,
.form-horizontal,
.login-wrap {
  input[type='text'],
  input[type='password'],
  textarea {
    font-size: 1.8rem;
    line-height: 1.41;
    border: solid 0.3px #d7d7d7;
  }
  .has-error {
    input[type='text'],
    textarea {
      // copy styles from bootstrap for error feedback
      border-color: $state-danger-text;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); // Redeclare so transitions work
      &:focus {
        border-color: darken($state-danger-text, 10%);
        // the focus box shadow is rather cheesy
        // box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($state-danger-text, 20%);
      }
    }
  }
}
hr {
  border-color: #acacac;
}

.form-signin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 320px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
}

// by default bg-primary just highlights the links within, this does it for the
// whole wrapper, and is used for eg card headings
.bg-primary.focusable:hover {
  background-color: darken($brand-primary, 10%);
  cursor: pointer;
}

@media (max-width: $screen-md-max) {
  // suppress gutters on this row
  // (except for wide screens)
  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class^='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .text-sm-right {
    text-align: right;
  }
}
