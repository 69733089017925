body {
  font-family: Nunito, 'Open Sans', sans-serif;
}
h1 {
  font-size: 2.8rem;
  line-height: 1.36;
  text-align: left;
  color: #4a4042;
}
h2 {
  font-size: 2rem;
  line-height: 1.36;
  text-align: left;
  color: #4a4042;
}
h3 {
  font-size: 2rem;
  line-height: 1.36;
  text-align: left;
  color: #686b6b;
}
h4 {
  font-size: 1.4rem;
  text-align: left;
  color: #686b6b;
}
