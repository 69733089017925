body {
  font-family: Nunito, "Open Sans", sans-serif;
}

h1 {
  font-size: 2.8rem;
  line-height: 1.36;
  text-align: left;
  color: #4a4042;
}

h2 {
  font-size: 2rem;
  line-height: 1.36;
  text-align: left;
  color: #4a4042;
}

h3 {
  font-size: 2rem;
  line-height: 1.36;
  text-align: left;
  color: #686b6b;
}

h4 {
  font-size: 1.4rem;
  text-align: left;
  color: #686b6b;
}

a {
  color: inherit;
  cursor: pointer;
}
a:hover {
  color: inherit;
}

.form input[type=text],
.form input[type=password],
.form textarea,
.form-horizontal input[type=text],
.form-horizontal input[type=password],
.form-horizontal textarea,
.login-wrap input[type=text],
.login-wrap input[type=password],
.login-wrap textarea {
  font-size: 1.8rem;
  line-height: 1.41;
  border: solid 0.3px #d7d7d7;
}
.form .has-error input[type=text],
.form .has-error textarea,
.form-horizontal .has-error input[type=text],
.form-horizontal .has-error textarea,
.login-wrap .has-error input[type=text],
.login-wrap .has-error textarea {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.form .has-error input[type=text]:focus,
.form .has-error textarea:focus,
.form-horizontal .has-error input[type=text]:focus,
.form-horizontal .has-error textarea:focus,
.login-wrap .has-error input[type=text]:focus,
.login-wrap .has-error textarea:focus {
  border-color: #843534;
}

hr {
  border-color: #acacac;
}

.form-signin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 320px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
}

.bg-primary.focusable:hover {
  background-color: #0a4b85;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .row.no-gutters > [class^=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .text-sm-right {
    text-align: right;
  }
}

